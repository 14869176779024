.home {
  height: calc(100vh - 96px);
  position: relative;
}

.cards {
  width: 100vw;
  height: 300px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: absolute;
  bottom: -240px;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
  padding: 0 36px;

  z-index: 2;

  text-align: left;
}

.card {
  height: 296px;
  width: 100%;

  padding: 24px 36px;

  background: url("../images/card-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;

  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.header h3 {
  font-size: 2rem;
  line-height: 32px;
  font-weight: 800;

  width: 20%;

  color: var(--teal);

  text-transform: uppercase;
}
.header-icon {
  background-color: var(--blue);
  width: 60px;
  height: 60px;

  border-radius: 50%;
}

.description {
  margin-top: 20px;
  text-align: left;

  color: var(--teal);

  width: 80%;
  height: calc(100% - 90px);
}
.description a {
  display: flex;
  align-items: center;
}
.description a::after {
  font-family: "Material Symbols Outlined";
  content: "\e5e1";
}

.banner {
  /* background = image location | repeat option | x y coordinates | size */
  background: url("../images/dog.svg") no-repeat -5% -5% / 20%,
    url("../images/cat.svg") no-repeat 100% -5% / 20%;
  background-color: var(--green);
}

.reptile-card {
  width: 400px;
  height: 244px;
  border-radius: 18px;

  padding: 12px 16px;

  display: flex;
  flex-direction: row;
  overflow: hidden;

  box-shadow: 0px 3.79837px 20.89106px 0px rgba(0, 0, 0, 0.2);
}

.reptile-card a {
  display: flex;
  align-items: center;
}

.reptile-card a::after {
  font-family: "Material Symbols Outlined";
  content: "\e5e1";
}

.reptile-card .circle {
  border-radius: 50%;
  height: 20rem;
  width: 20rem;
  margin-top: 40px;

  background: var(--green);
  position: absolute;
  z-index: -2;
}
.slider-animal-desc {
  display: none;
}

.why_choose_us {
  margin-top: 145px;
}
.why-vector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}
.wh-vector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vector-description {
  color: #021939;
  text-align: center;
  line-height: 116%; /* 27.84px */
  letter-spacing: -0.48px;
}

.services_block {
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: space-between;
}

.services_left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 56%;
  /* padding-left: 4rem; */
}

.services_right {
  display: flex;
  justify-content: center;
  padding-right: 4rem;
}
.services_right div img {
  height: 600px;
  width: fit-content;
}
.services_left_content {
  overflow: hidden;
  width: 320px;
  height: 340px;
  padding: 25px 16px 0px 16px;
  flex-shrink: 0;
  border-radius: 17.984px;
  border: 1px solid var(--Green, #06a37e);
  background: var(--Teal-white, #fefef2);
}

.services_left_header {
  margin-top: 20px;
  color: var(--Aqua-Blue, #021939);
  text-align: center;
  font-size: 40.776px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 45.776px */
}

.services_left_middle {
  margin-top: 30px;
  height: 90px;
  color: var(--Aqua-Blue, #021939);
  text-align: center;
  font-size: 14.986px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 17.984px */
  letter-spacing: 0.3px;
}

.services_left_link {
  margin-top: 45px;
  color: #000;
  text-align: center;
  font-size: 14.986px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14.986px */
}
.services_left_item img {
  height: 134px;
  width: 134px;
  position: relative;
  overflow: hidden;
  margin-top: -95px;
  margin-left: calc(50% + 35px);
}
.bandage_img {
  transform: rotate(-17.046deg);
}
.services_right img {
  width: 838.956px;
  height: 700.174px;
  transform: rotate(-4.542deg);
  flex-shrink: 0;
  position: relative;
  top: 0px;
  left: 0px;
}
.services_right_img2 {
  margin-top: -100%;
  z-index: 1;
}

.swiper-wrapper {
  width: 100vw
}

.mapsGoogle {
  height: 32rem;
  width: 72%;
  margin: 4rem auto;
  margin-bottom: 6rem;
  border-radius: 12px;
  z-index: 1;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

.leaflet-popup-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.leaflet-popup-content p {
  margin: 0;
}

.CustomerReviewCards {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 98vw;
  gap: 32px;
  margin-left: 2vh;
}

.gradient {
  z-index: 2;
  background: linear-gradient(
    90deg,
    #fefef2 0%,
    #fefef2 20%,
    rgba(254, 254, 242, 0) 50%
  );
}

.CustomerReviewCards {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.CustomerReviewCards::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.CustomerReviewCards .swiper-slide-shadow-left{
  border-radius: 16px;
}

.CustomerReviewCards .swiper-slide-shadow-right{
  border-radius: 16px;
}

.review {
  border-radius: 16px;
  border: 0.834px solid #139f7e;
  background: #e5f9e9;
  width: 680px;
  height: 400px;
  flex-shrink: 0;
  text-align: start;
}

.review_header {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 34px;
  gap: 20px;
  align-items: center;
  text-align: start;
}
.review_photo {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 50%;
}

.review_photo img {
  border-radius: 50%;
}

.review_header_text {
  display: flex;
  flex-direction: column;
}
.review_header_name {
  color: #139f7e;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%; /* 33.949px */
}

.review_header_date {
  margin-top: 12px;
  color: #139f7e;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 90%; /* 18.022px */
}

.review_content {
  margin-top: 48px;
  margin-left: 34px;
}

.review_title {
  color: #139f7e;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; /* 37.545px */
}

.review_description {
  margin-top: 12px;
  color: #139f7e;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 24.029px */
  width: 90%;
}

.potrait-video{
  display: none;
}

@media (max-width: 1440px) {
  .services_left {
    padding-left: 2.75rem;
  }
  .services_left_header {
    font-size: 36px;
  }
  .services_left_middle {
    font-size: 14px;
  }
  .services_left_content {
    overflow: hidden;
    width: 300px;
    height: 320px;
  }
  .services_right {
    padding-right: 2.75rem;
  }
  .services_right div img {
    height: 500px;
  }
}
@media (max-width: 1280px) {
  .card {
    padding: 24px;

    height: 280px;
  }

  .card .header h3 {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .card .description {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
  .header-icon {
    width: 50px;
    height: 50px;
  }
  .header-icon img {
    height: 50%;
  }

  .reptile-card {
    width: 360px;
  }
  .reptile-card h2 {
    font-size: 1.7rem;
  }
  .reptile-card p {
    font-size: 0.7rem;
  }
  .reptile-card a {
    font-size: 0.8rem;
  }
  .reptile-card img {
    height: 18rem;
  }
  .banner h3 {
    font-size: 1.75rem;
  }
  .banner h2 {
    font-size: 3.75rem;
  }
  .banner {
    background: url("../images/dog.svg") no-repeat -5% -25% / 20%,
      url("../images/cat.svg") no-repeat 100% -25% / 20%;
    background-color: var(--green);
  }
  .animal-heading {
    font-size: 5.2rem;
    text-align: center;
  }
  .animal-description {
    font-size: 1rem;
    line-height: 1.2rem;
    width: 70%;
  }
  .footer {
    height: 22rem;
  }
  .mapsGoogle {
    height: 32rem;
    width: 80%;
    margin: 3.6rem auto;
    margin-bottom: 5rem;
    border-radius: 12px;
  }
  .services_left {
    margin: auto;
    padding: 0;
    justify-content: center;
    width: 80%;
  }
  .services_left_header {
    margin-top: 12px;
  }
  .services_left_content {
    width: 360px;
    height: 320px;
  }
  .services_right {
    display: none;
  }
}

@media (max-width: 1160px) {
  .reptile-card {
    width: 300px;
    height: 212px;
  }
  .reptile-card p {
    font-size: 0.65rem;
    line-height: 0.75rem;
  }
  .reptile-card h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 1000px) {
  .cards {
    flex-direction: column;
    bottom: -380px;
  }
  .card {
    width: 90%;
    height: 200px;
  }
  .banner-content {
    height: 60rem;
  }
  .social {
    width: 25%;
  }
  .social img {
    height: 9.25rem;
    margin-top: 3rem;
  }
  .social-links a {
    width: 2.25rem;
    height: 2.25rem;
  }
  .social-links div {
    gap: 0.9rem;
  }
  .links li {
    font-size: 1rem;
    margin: 0.25rem 0;
  }
  .links h3 {
    font-size: 2rem;
  }

  .mapsGoogle {
    height: 32rem;
    width: 84%;
    margin: 2.8rem auto;
    margin-bottom: 5rem;
    border-radius: 12px;
  }

  .potrait-video{
    display: block;
  }
  .landscape-video{
    display: none;
  }
}
@media (max-width: 900px) {
  .home div {
    font-size: 5.2rem;
  }
  .grid-animal-desc {
    display: none;
  }
  .slider-animal-desc {
    display: block;
  }
  .banner h3 {
    font-size: 1.45rem;
  }
  .banner h2 {
    font-size: 3rem;
  }
  .banner {
    background: url("../images/dog.svg") no-repeat -5% -35% / 25%,
      url("../images/cat.svg") no-repeat 100% -15% / 25%;
    background-color: var(--green);
  }
  .reptile-card {
    width: 400px;
    height: 244px;

    padding: 12px 16px;
  }
  .reptile-card h2 {
    font-size: 2rem;
  }
  .reptile-card p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .reptile-card a {
    font-size: 1rem;
  }
  .reptile-card img {
    height: 18rem;
  }
  .animal-heading {
    font-size: 5rem;
    width: 500px;
    margin: auto;
  }
  .services_left {
    width: 90%;
  }
  .services_left_header {
    font-size: 30px;
  }
  .services_left_middle {
    font-size: 14px;
  }
  .services_left_content {
    width: 320px;
    height: 320px;
  }
  .CustomerReviewCards {
    gap: 36px;
  }
  .review {
    width: 640px;
    height: 400px;
  }
  .review_header_name{
    font-size: 32px;
  }
  .review_title{
    font-size: 24px;
    font-weight: 700;
  }
  .review_content {
    margin-top: 32px;
  }
}
@media (max-width: 768px) {
  .home div {
    font-size: 4rem;
  }
  .banner-content {
    height: 56rem;
  }
  .banner {
    margin-bottom: 6rem;
    padding: 8px 12px;
    height: 15rem;
  }
  .banner {
    /* background = image location | repeat option | x y coordinates | size */
    background: url("../images/dog.svg") no-repeat -20% -35% / 40%,
      url("../images/cat.svg") no-repeat 120% -35% / 40%;
    background-color: var(--green);
  }
  .banner h2 {
    width: 28rem;
  }
  .banner p {
    font-size: 0.85rem;
  }
  .mySwiper {
    width: 90%;
  }
  .animal-heading {
    font-size: 4rem;
    width: 24rem;
  }
  .animal-description {
    font-size: 0.85rem;
    width: 90%;
  }

  .description{
    margin-top: 12px;
  }

  .description p {
    font-size: 0.9rem;
    line-height: 0.95rem;
  }

  .description a {
    margin-top: 12px
  }

  .footer {
    height: 20rem;
  }

  .social {
    width: 25%;
  }
  .social img {
    height: 7rem;
    margin-top: 4rem;
  }
  .social-links a {
    width: 1.5rem;
    height: 1.5rem;
  }
  .social-links div {
    gap: 0.5rem;
  }
  .links {
    padding: 2rem 1.75rem;
  }
  .links li {
    font-size: 0.9rem;
    margin: 0;
  }
  .links h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .mapsGoogle {
    height: 24rem;
    width: 86%;
    margin: 2rem auto;
    margin-bottom: 5rem;
    border-radius: 12px;
  }
  .CustomerReviewCards {
    gap: 40px;
  }
  .review {
    width: 600px;
    height: 360px;
  }
  .review_photo {
    width: 80px;
    height: 80px;
  }
  .review_header_name{
    font-size: 28px;
  }
  .review_header_date{
    font-size: 16px;
  }
  .review_title{
    font-size: 22px;
    font-weight: 700;
  }
  .review_description {
    font-size: 16px;
  }
  .review_content {
    margin-top: 32px;
  }
}

@media (max-width: 600px) {
  .reptile-card {
    width: 300px;
    height: 212px;
  }
  .reptile-card p {
    font-size: 0.65rem;
    line-height: 0.75rem;
  }
  .reptile-card a {
    font-size: 0.75rem;
  }
  .reptile-card img {
    height: 16rem;
  }
  .reptile-card h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .home-header {
    margin-left: 1rem;
    bottom: 6rem;
  }
  .home-header h1 {
    font-size: 3rem;
  }
  .cards {
    bottom: -400px;
    padding: 0 16px;
  }
  .card{
    height: 240px;
    width: 100%;
  }
  .card .description p {
    font-size: 0.75rem;
  }
  .card .description {
    font-size: 0.85rem;
    line-height: 0.8rem;
    margin-top: 8px;
  }
  .card .header h3{
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .header-icon{
    width: 40px;
    height: 40px;
  }
  .footer {
    height: 16rem;
  }
  .social {
    width: 30%;
  }
  .social img {
    height: 6rem;
    margin-top: 4rem;
  }
  .social-links a {
    width: 1.2rem;
    height: 1.2rem;
  }
  .social-links div {
    gap: 0.75rem;
  }
  .links {
    padding: 0;
    padding-left: 1.5rem;
  }
  .links li {
    font-size: 0.8rem;
    margin: -0.3rem 0;
  }
  .links h3 {
    margin-top: 2rem;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .mapsGoogle {
    height: 16rem;
    width: 86%;
    margin: 2rem auto;
    margin-bottom: 4rem;
    border-radius: 12px;
  }
  .banner-content {
    height: 62rem;
  }
  .mySwiper {
    width: 98%;
  }
  .swiper-button-prev, .swiper-button-next{
    display: none;
  }

  .banner {
    /* background = image location | repeat option | x y coordinates | size */
    background: url("../images/dog.svg") no-repeat -110px 20px / 60%,
      url("../images/cat.svg") no-repeat 170% 20px / 60%;
    background-color: var(--green);
  }
  
  .banner h2{
    font-size: 2.5rem;
    width: 22rem;
    margin: 0;
  }
  .banner h3{
    font-size: 1.25rem;
  }

  .banner p {
    font-size: 0.75rem;
  }

  .animal-heading{
    font-size: 3.25rem;
    width: 20rem;
  }
  .animal-description {
    font-size: 0.8rem;
    width: 85%;
    margin-top: 1.5rem;
  }
  #testimonials .animal-heading{
    font-size: 3rem;
  }
  .wh-vector p{
    font-size: 0.64rem;
    font-weight: 600;
  }
}
