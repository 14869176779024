@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  :root {
    --green: #06a37e;
    --teal: #fefef2;
    --blue: #021939
  }
}

*, html, body{
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  width: 100vw;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  background-color:#fefef2;
  color: var(--blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  display: block;
}
