.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    box-shadow: 0 2px -2px rgba(0,0,0,.2);
}

.logo{
    width: fit-content;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 12px;

    font-size: 16px;
}
.logo img{
    height: 36px;
    margin-right: 12px;
}
.nav-items{
    margin-right: 1vw;
    display: flex;
    gap: 1.6vw;
    justify-content: right;

    font-weight: 500;
}
.nav-item:hover{
    color: var(--green);
    cursor: pointer;
}

.species:hover .inner-nav-species {
    display: block;
}

.inner-nav-species{
    color: var(--blue);
    display: none;
}

.inner-nav-species a:hover{
    color: var(--green);
}

.nav-item{
    display: flex;
    align-items: center;
}

.nav-item-arrow:after{
    font-family: 'Material Symbols Outlined';
    content: '\e5cf';
    margin-left: 0.25rem;
    transform: scale(1.5);
}

.hamburger{
    display: none;
    z-index: 2;
    -webkit-user-select: none;
}

.hamburger span {
    display: block;
    width: 32px;
    height: 3px;
    margin-bottom: 6px;
    background: var(--blue);
    border-radius: 3px;
    position: relative;
    z-index: 4;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
}

.hamburger input{
    display: block;
    width: 32px;
    height: 20px;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 5; /* and place it over the hamburger */
    -webkit-touch-callout: none;

    &:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
    &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }
    }
    &:checked ~ .menuItem {
        transform: none;
    }
    &:checked:hover ~ span {
        background: var(--teal);
    }
}

.menuItem {
    position: absolute;
    width: 100vw;
    height: 100vh;
    padding: 50px 0px;
    padding-top: 125px;
    background: var(--green);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    top: 0;
    right: 0;
    margin: 0 0 0 0;
    transform: translate(100%, 0);
    z-index: 3;
}

.menuItem li {
    padding: 10px 0;
    font-size: 22px;
}

.nav-item-mobile {
    transition: padding 0.5s cubic-bezier(0.77,0.2,0.05,1.0), 
    color 0.25s cubic-bezier(0.77,0.2,0.05,1.0);
    &:hover {
      padding-left: 10px;
      color: var(--teal);
      cursor: pointer;
    }
}

@media(max-width:991px) {
    .nav-items {
        display: none;
    }

    .hamburger {
        display: block;
      }
}
